(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

var Menu = function Menu(args) {
	var that = this;
	this.init = function () {
		this.cacheDom();
		bindEvents();
	};
	this.cacheDom = function () {
		this.header = document.querySelector(args.headerItem);
		this.menu = document.querySelector(args.menuWrapper);
		this.open = document.querySelector(args.menuOpen);
	};
	function OpenMenu() {
		that.header.classList.add('page-header--move');
		that.open.classList.toggle('is-active');
		that.menu.classList.toggle('is-active');
	}
	function bindEvents() {
		that.open.addEventListener('click', OpenMenu);
	}
};
module.exports = Menu;

},{}],2:[function(require,module,exports){
'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Sidebar = function () {
	function Sidebar(args) {
		_classCallCheck(this, Sidebar);

		this.sidebaropen = document.querySelectorAll(args.sidebaropen);
		this.sidebar = document.querySelectorAll(args.sidebar);
		this.dataElId = args.dataElId;
		this.activeClass = args.activeClass ? args.activeClass : 'is-active';
		this.bindEvents();
	}

	_createClass(Sidebar, [{
		key: 'bindEvents',
		value: function bindEvents() {
			var _this = this;

			this.sidebaropen.forEach(function (el) {

				el.addEventListener('click', function () {
					_this.toggle_sidebar(el);
					el.classList.add(_this.activeClass);
				});
			});

			window.addEventListener('load', function (event) {
				// console.log('page is fully loaded');
				var active = document.querySelector(".menuactive").querySelector(".js-sidebar-menu");
				active.classList.add(_this.activeClass);
			});
		}
	}, {
		key: 'toggle_sidebar',
		value: function toggle_sidebar(el) {
			var _this2 = this;

			this.sidebaropen.forEach(function (el) {
				el.classList.remove(_this2.activeClass);
			});
			this.sidebar.forEach(function (el) {
				el.classList.remove(_this2.activeClass);
			});
			var id = el.getAttribute(this.dataElId);
			id = document.querySelector(id);
			console.log(id);
			id.classList.add(this.activeClass);
		}
	}]);

	return Sidebar;
}();

module.exports = Sidebar;

},{}],3:[function(require,module,exports){
'use strict';

var _menu = require('./modules/menu.js');

var _menu2 = _interopRequireDefault(_menu);

var _sidebar = require('./modules/sidebar.js');

var _sidebar2 = _interopRequireDefault(_sidebar);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var menu = new _menu2.default({
	headerItem: '.page-header',
	menuOpen: '.js-mobile',
	menuWrapper: ".js-menu"
});
menu.init();

var sidebar = new _sidebar2.default({
	sidebaropen: '.js-sidebar',
	sidebar: '.js-sidebar-menu',
	dataElId: 'data-menu',
	activeClass: 'is-active'
});

// try and screen functions
try {
	var top_slider = new Swiper('.zoom-slider', {
		slidesPerView: 1,
		autoplay: true,
		effect: 'fade',
		speed: 3500,
		fadeEffect: {
			crossFade: true
		}
	});
	var swiper = new Swiper('.swiper-container', {
		navigation: {
			nextEl: '.about-btn-next',
			prevEl: '.about-btn-prev'
		}
	});
} catch (error) {}

window.onscroll = function () {
	move_header();
};
var header = document.querySelector('.page-header');
var sticky = header.offsetTop;
function move_header() {
	if (window.pageYOffset > sticky) {
		header.classList.add('page-header--move');
	} else {
		header.classList.remove('page-header--move');
	}
}
var scroll = document.querySelectorAll('.js-scrollto');
scroll.forEach(function (el) {
	el.addEventListener('click', function () {
		document.getElementById("ofert-home").scrollIntoView();
	});
});

},{"./modules/menu.js":1,"./modules/sidebar.js":2}]},{},[3]);
